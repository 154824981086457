import update from 'immutability-helper';
import { IRolePermissState, IRolePermissTypes } from 'redux/types/account/rolePermissTypes';

const initialState: IRolePermissState = {
  loadingPermiss: false,
  loadingList: false,
  loadingDetail: false,
  dataMasterPermiss: null,
  dataMasterRole: null,
  dataList: null,
  dataDetail: null,
  error: '',
  pagination: null,
};

export default (state: IRolePermissState = initialState, { type, payload }) => {
  switch (type) {
    case IRolePermissTypes.GET_LIST_PERMISS_PENDING:
      return update(state, {
        loadingPermiss: { $set: true },
      });
    case IRolePermissTypes.GET_LIST_PERMISS_SUCCESS:
      return update(state, {
        loadingPermiss: { $set: false },
        dataMasterPermiss: { $set: payload.data },
      });
    case IRolePermissTypes.GET_LIST_PERMISS_FAIL:
      return update(state, {
        loadingPermiss: { $set: false },
        dataMasterPermiss: { $set: null },
        error: { $set: payload },
      });
    case IRolePermissTypes.GET_MASTER_ROLE_PENDING:
      return update(state, {
        loadingPermiss: { $set: true },
      });
    case IRolePermissTypes.GET_MASTER_ROLE_SUCCESS:
      return update(state, {
        loadingPermiss: { $set: false },
        dataMasterRole: { $set: payload.data },
      });
    case IRolePermissTypes.GET_MASTER_ROLE_FAIL:
      return update(state, {
        loadingPermiss: { $set: false },
        dataMasterPermiss: { $set: null },
        error: {$set: payload},
      });
    case IRolePermissTypes.GET_LIST_ROLE_PENDING:
      return update(state, {
        loadingList: { $set: true },
      });
    case IRolePermissTypes.GET_LIST_ROLE_SUCCESS:
      return update(state, {
        loadingList: { $set: false },
        dataList: { $set: payload.data },
        pagination: { $set: payload.paginator },
      });
    case IRolePermissTypes.GET_LIST_ROLE_FAIL:
      return update(state, {
        loadingList: { $set: false },
        dataList: { $set: null },
        error: { $set: payload },
      });
    case IRolePermissTypes.GET_ROLE_PENDING:
      return update(state, {
        loadingDetail: { $set: true },
      });
    case IRolePermissTypes.GET_ROLE_SUCCESS:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetail: { $set: payload.data },
      });
    case IRolePermissTypes.GET_ROLE_FAIL:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetail: { $set: null },
        error: { $set: payload },
      });
    default:
      return state;
  }
};
