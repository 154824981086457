import update from 'immutability-helper';
import { IMerchantAppState, IMerchantAppTypes } from 'redux/types/account/merchantAppTypes';

const initialState: IMerchantAppState = {
  loadingList: false,
  loadingDetail: false,
  loadingMasterMerchant: false,
  dataList: null,
  dataDetail: null,
  dataMasterMerchant: null,
  error: '',
  pagination: null,
};

export default (state: IMerchantAppState = initialState, { type, payload }) => {
  switch (type) {
    case IMerchantAppTypes.GET_LIST_MERCHANT_APP_PENDING:
      return update(state, {
        loadingList: { $set: true },
      });
    case IMerchantAppTypes.GET_LIST_MERCHANT_APP_SUCCESS:
      return update(state, {
        loadingList: { $set: false },
        dataList: { $set: payload.data },
        pagination: { $set: payload.paginator },
      });
    case IMerchantAppTypes.GET_LIST_MERCHANT_APP_FAIL:
      return update(state, {
        loadingList: { $set: false },
        dataList: { $set: null },
        // error: payload,
      });
    case IMerchantAppTypes.GET_MERCHANT_APP_PENDING:
      return update(state, {
        loadingDetail: { $set: true },
      });
    case IMerchantAppTypes.GET_MERCHANT_APP_SUCCESS:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetail: { $set: payload.data },
      });
    case IMerchantAppTypes.GET_MERCHANT_APP_FAIL:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetail: { $set: null },
        error: payload,
      });
    case IMerchantAppTypes.GET_MASTER_MERCHANT_APP_PENDING:
      return update(state, {
        loadingMasterMerchant: { $set: true },
        dataMasterMerchant: { $set: null },
      });
    case IMerchantAppTypes.GET_MASTER_MERCHANT_APP_SUCCESS:
      return update(state, {
        loadingMasterMerchant: { $set: false },
        dataMasterMerchant: { $set: payload.data },
      });
    case IMerchantAppTypes.GET_MASTER_MERCHANT_APP_FAIL:
      return update(state, {
        loadingMasterMerchant: { $set: false },
        dataMasterMerchant: { $set: null },
        // error: payload,
      });
    default:
      return state;
  }
};
