import update from 'immutability-helper';
import { IUserManageState, IUserManageTypes } from 'redux/types/account/userManageTypes';

const initialState: IUserManageState = {
  loadingList: false,
  loadingDetail: false,
  dataList: null,
  dataDetail: null,
  error: '',
  pagination: null,
};

export default (state: IUserManageState = initialState, { type, payload }) => {
  switch (type) {
    case IUserManageTypes.GET_LIST_USER_PENDING:
      return update(state, {
        loadingList: { $set: true },
      });
    case IUserManageTypes.GET_LIST_USER_SUCCESS:
      return update(state, {
        loadingList: { $set: false },
        dataList: { $set: payload.data },
        pagination: { $set: payload.paginator },
      });
    case IUserManageTypes.GET_LIST_USER_FAIL:
      return update(state, {
        loadingList: { $set: false },
        dataList: { $set: null },
        pagination: { $set: null },
        error: { $set: payload },
      });
    case IUserManageTypes.GET_USER_PENDING:
      return update(state, {
        loadingDetail: { $set: true },
      });
    case IUserManageTypes.GET_USER_SUCCESS:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetail: { $set: payload.data },
      });
    case IUserManageTypes.GET_USER_FAIL:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetail: { $set: null },
        error: payload,
      });
    default:
      return state;
  }
};
