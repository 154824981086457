export enum IUserManageTypes {
  GET_LIST_PERMISS_PENDING = 'GET_LIST_PERMISS_PENDING',
  GET_LIST_PERMISS_FAIL = 'GET_LIST_PERMISS_FAIL',
  GET_LIST_PERMISS_SUCCESS = 'GET_LIST_PERMISS_SUCCESS',

  GET_LIST_USER_PENDING = 'GET_LIST_USER_PENDING',
  GET_LIST_USER_FAIL = 'GET_LIST_USER_FAIL',
  GET_LIST_USER_SUCCESS = 'GET_LIST_USER_SUCCESS',

  GET_USER_PENDING = 'GET_USER_PENDING',
  GET_USER_FAIL = 'GET_USER_FAIL',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',

  CRUD_USER_PENDING = 'CRUD_USER_PENDING',
  CRUD_USER_FAIL = 'CRUD_USER_FAIL',
  CRUD_USER_SUCCESS = 'CRUD_USER_SUCCESS',
}

export interface IUserManageList {
  email: string;
  full_name: string;
  phone: string;
  roles: Role[];
  status: number;
  user_id: number;
}
export interface IUserManageDetail {
  email: string;
  full_name: string;
  phone: string;
  roles: Role[];
  status: number;
  user_id: number;
}
export interface IUserManageBodyCreate {
  full_name: string;
  email: string;
  phone: string;
  roles: Role[];
}
export interface IUserManageBodyUpdate {
  full_name: string;
  email: string;
  phone: string;
  roles: Role[];
}
export interface Role {
  role_id: number;
  role_name: string;
}
export interface Role {
  role_id: number;
  role_name: string;
}

export interface IUserManageState {
  loadingList: boolean;
  loadingDetail: boolean;
  dataList: IUserManageList[] | null;
  dataDetail: IUserManageDetail | null;
  error: any;
  pagination?: any;
}
