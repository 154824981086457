export enum IMerchantAppTypes {
  GET_LIST_MERCHANT_APP_PENDING = 'GET_LIST_MERCHANT_APP_PENDING',
  GET_LIST_MERCHANT_APP_FAIL = 'GET_LIST_MERCHANT_APP_FAIL',
  GET_LIST_MERCHANT_APP_SUCCESS = 'GET_LIST_MERCHANT_APP_SUCCESS',

  GET_MERCHANT_APP_PENDING = 'GET_MERCHANT_APP_PENDING',
  GET_MERCHANT_APP_FAIL = 'GET_MERCHANT_APP_FAIL',
  GET_MERCHANT_APP_SUCCESS = 'GET_MERCHANT_APP_SUCCESS',

  GET_MASTER_MERCHANT_APP_PENDING = 'GET_MASTER_MERCHANT_APP_PENDING',
  GET_MASTER_MERCHANT_APP_FAIL = 'GET_MASTER_MERCHANT_APP_FAIL',
  GET_MASTER_MERCHANT_APP_SUCCESS = 'GET_MASTER_MERCHANT_APP_SUCCESS',

  CRUD_MERCHANT_APP_PENDING = 'CRUD_MERCHANT_APP_PENDING',
  CRUD_MERCHANT_APP_FAIL = 'CRUD_MERCHANT_APP_FAIL',
  CRUD_MERCHANT_APP_SUCCESS = 'CRUD_MERCHANT_APP_SUCCESS',
}

export interface IMerchantAppList {
  mua_id: number;
  m_id: number;
  m_code: string;
  m_name: string;
  mua_name: string;
  mua_phone: string;
  mua_key: string;
  mua_status: number;
  insert_date: string;
  insert_by: string;
  updated_date: string;
  updated_by: string;
  deleted_date: string;
  deleted_by: string;
}

export interface PaginationMerchantApp {
  total_records: number;
  total_page: number;
  current_page: number;
  per_page: number;
  next: number;
  previous: number;
}

export interface IMerchantAppDetail {
  mua_id: number;
  m_id: number;
  m_name: string;
  m_code: string;
  mua_name: string;
  mua_phone: string;
  mua_key: string;
  mua_status: number;
  insert_date: string;
  insert_by: string;
  updated_date: string;
  updated_by: string;
  deleted_date: string;
  deleted_by: string;
}

export interface IMasterMerchantList {
  m_id: number;
  m_name: string;
  m_code: string;
}

export interface IMerchantAppState {
  loadingList: boolean;
  loadingDetail: boolean;
  loadingMasterMerchant: boolean;
  dataList: IMerchantAppList[] | null;
  dataDetail: IMerchantAppDetail | null;
  dataMasterMerchant: IMasterMerchantList[] | null;
  error: any;
  pagination?: PaginationMerchantApp;
}
