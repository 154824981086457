export enum IRolePermissTypes {
  GET_LIST_PERMISS_PENDING = 'GET_LIST_PERMISS_PENDING',
  GET_LIST_PERMISS_FAIL = 'GET_LIST_PERMISS_FAIL',
  GET_LIST_PERMISS_SUCCESS = 'GET_LIST_PERMISS_SUCCESS',

  GET_MASTER_ROLE_PENDING = 'GET_MASTER_ROLE_PENDING',
  GET_MASTER_ROLE_FAIL = 'GET_MASTER_ROLE_FAIL',
  GET_MASTER_ROLE_SUCCESS = 'GET_MASTER_ROLE_SUCCESS',

  GET_LIST_ROLE_PENDING = 'GET_LIST_ROLE_PENDING',
  GET_LIST_ROLE_FAIL = 'GET_LIST_ROLE_FAIL',
  GET_LIST_ROLE_SUCCESS = 'GET_LIST_ROLE_SUCCESS',

  GET_ROLE_PENDING = 'GET_ROLE_PENDING',
  GET_ROLE_FAIL = 'GET_ROLE_FAIL',
  GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS',

  CRUD_ROLE_PENDING = 'CRUD_ROLE_PENDING',
  CRUD_ROLE_FAIL = 'CRUD_ROLE_FAIL',
  CRUD_ROLE_SUCCESS = 'CRUD_ROLE_SUCCESS',
}

export interface IMasterPermission {
  permission_keys: PermissionKey[] | null;
  pkc_id: number;
  pkc_name: string;
  pkc_status: number;
}

export interface PermissionKey {
  command: Command;
  permission_key: string;
  permission_key_alias: string;
  permission_key_category_id: number;
  permission_key_id: number;
  permission_key_status: number;
  source: string;
}

export enum Command {
  Create = 'create',
  Delete = 'delete',
  Edit = 'edit',
  Empty = '',
  View = 'view',
}

export interface IRoleList {
  client_id: number;
  created_date: string;
  env: number;
  package_type: number;
  role_id: number;
  role_name: string;
  role_type: string;
  status: number;
  updated_date: string;
}

export interface IRoleDetail {
  client_id: number;
  created_date: Date;
  env: number;
  package_type: number;
  permissions: PermissionDetail[];
  role_id: number;
  role_name: string;
  role_type: string;
  status: number;
  updated_date: Date;
}

export interface PermissionDetail {
  permission_key: string;
  permission_key_id: number;
  role_id: number;
  role_permission_id: number;
  status: number;
}

export interface IRoleBodyCreate {
  role_name: string;
  status: number;
  permissions: PermissionBody[];
}

export interface PermissionBody {
  permission_key: string;
  permission_key_id: number;
}

export interface IRoleBodyUpdate {
  role_name: string;
  status: number;
  permissions: PermissionBodyUpdate[];
}

export interface PermissionBodyUpdate {
  role_id?: number;
  permission_key: string;
  permission_key_id: number;
}

export interface IRolePermissState {
  loadingList: boolean;
  loadingDetail: boolean;
  loadingPermiss: boolean;
  dataMasterPermiss: IMasterPermission[] | null;
  dataList: IRoleList[] | null;
  dataMasterRole: IRoleList[] | null;
  dataDetail: IRoleDetail | null;
  error: any;
  pagination?: any;
}
