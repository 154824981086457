import update from 'immutability-helper';
import { IMerchantFeeState, IMerchantFeeTypes } from 'redux/types/settings/merchantFeeConfigTypes';

const initialState: IMerchantFeeState = {
  loadingList: false,
  loadingDetail: false,
  loadingMasterPChannel: false,
  dataParentList: null,
  dataDetailList: null,
  dataDetail: null,
  dataMasterPChannel: null,
  error: '',
  paginationDetail: null,
  paginationParent: null,
};

export default (state: IMerchantFeeState = initialState, { type, payload }) => {
  switch (type) {
    case IMerchantFeeTypes.GET_LIST_MERCHANTFEE_PENDING:
      return update(state, {
        loadingList: { $set: true },
      });
    case IMerchantFeeTypes.GET_LIST_MERCHANTFEE_SUCCESS:
      return update(state, {
        loadingList: { $set: false },
        dataParentList: { $set: payload.data },
        paginationParent: { $set: payload.paginator },
      });
    case IMerchantFeeTypes.GET_LIST_MERCHANTFEE_FAIL:
      return update(state, {
        loadingList: { $set: false },
        dataParentList: { $set: null },
        error: { $set: payload },
      });
    case IMerchantFeeTypes.GET_DETAIL_LIST_MERCHANTFEE_PENDING:
      return update(state, {
        loadingDetail: { $set: true },
      });
    case IMerchantFeeTypes.GET_DETAIL_LIST_MERCHANTFEE_SUCCESS:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetailList: { $set: payload.data },
        paginationDetail: { $set: payload.paginator },
      });
    case IMerchantFeeTypes.GET_DETAIL_LIST_MERCHANTFEE_FAIL:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetailList: { $set: null },
        error: { $set: payload },
      });
    case IMerchantFeeTypes.MASTER_CHANNEL_MERCHANTFEE_PENDING:
      return update(state, {
        loadingMasterPChannel: { $set: true },
      });
    case IMerchantFeeTypes.MASTER_CHANNEL_MERCHANTFEE_SUCCESS:
      return update(state, {
        loadingMasterPChannel: { $set: false },
        dataMasterPChannel: { $set: payload.data },
      });
    case IMerchantFeeTypes.MASTER_CHANNEL_MERCHANTFEE_FAIL:
      return update(state, {
        loadingMasterPChannel: { $set: false },
        dataMasterPChannel: { $set: null },
        error: { $set: payload },
      });
    case IMerchantFeeTypes.CRUD_MERCHANTFEE_PENDING:
      return update(state, {
        // loadingDetail: { $set: true },
      });
    case IMerchantFeeTypes.CRUD_MERCHANTFEE_SUCCESS:
      return update(state, {
        // loadingDetail: { $set: false },
        dataDetail: { $set: payload.data },
      });
    case IMerchantFeeTypes.CRUD_MERCHANTFEE_FAIL:
      return update(state, {
        loadingDetail: { $set: false },
        dataDetail: { $set: null },
        error: { $set: payload },
      });
    default:
      return state;
  }
};
