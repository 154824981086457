export enum IMerchantFeeTypes {
  GET_LIST_MERCHANTFEE_PENDING = 'GET_LIST_MERCHANTFEE_PENDING',
  GET_LIST_MERCHANTFEE_SUCCESS = 'GET_LIST_MERCHANTFEE_SUCCESS',
  GET_LIST_MERCHANTFEE_FAIL = 'GET_LIST_MERCHANTFEE_FAIL',

  GET_DETAIL_LIST_MERCHANTFEE_PENDING = 'GET_DETAIL_LIST_MERCHANTFEE_PENDING',
  GET_DETAIL_LIST_MERCHANTFEE_SUCCESS = 'GET_DETAIL_LIST_MERCHANTFEE_SUCCESS',
  GET_DETAIL_LIST_MERCHANTFEE_FAIL = 'GET_DETAIL_LIST_MERCHANTFEE_FAIL',

  CRUD_MERCHANTFEE_PENDING = 'CRUD_MERCHANTFEE_PENDING',
  CRUD_MERCHANTFEE_SUCCESS = 'CRUD_MERCHANTFEE_SUCCESS',
  CRUD_MERCHANTFEE_FAIL = 'CRUD_MERCHANTFEE_FAIL',

  MASTER_CHANNEL_MERCHANTFEE_PENDING = 'MASTER_CHANNEL_MERCHANTFEE_PENDING',
  MASTER_CHANNEL_MERCHANTFEE_SUCCESS = 'MASTER_CHANNEL_MERCHANTFEE_SUCCESS',
  MASTER_CHANNEL_MERCHANTFEE_FAIL = 'MASTER_CHANNEL_MERCHANTFEE_FAIL',
}

export interface IPaginationMerchantFee {
  total: number;
  per_page: number;
  from: number;
  to: number;
  first_page: number;
  last_page: number;
  prev_page: number;
  current_page: number;
  next_page: number;
}

export interface IMerchantFeeParentList {
  id: number;
  code: string;
  name: string;
  date_insert: Date;
  date_update: Date;
  date_delete: Date;
  package_type: number;
  parent: string;
  management_type: string;
  pic_name: string;
}

export interface IMerchantFeeDetailList {
  id: number;
  pm_id: number;
  pm_name: string;
  pm_code: string;
  m_code: string;
  pc_id: number;
  pc_code: string;
  pc_name: string;
  ie: 1 | 2; // 1:include, 2: exlude
  fee_type: 1 | 2 | 3; // 1:Amount, 2: Persentage, 3: Mix
  fee: number;
  procentage: number;
  service_fee: number;
  status: 0 | 1; // 0:Inactive, 1:active
  inserted_by: number;
  updated_by: number;
  created: string;
  updated: string; // update date
}

export interface IMerchantDataDetail {
  name: string;
  code: string;
}

export interface IMerchantFeeBody {
  merchant_code: number;
  pc_code: string;
  ie?: 1 | 2;
  fee_type: 1 | 2 | 3;
  fee?: number;
  procentage?: number;
  status: 0 | 1;
}

export interface IMerchantPChannelMaster {
  pcId: number;
  pmId: number;
  pcCode: string;
  pcName: string;
  pcPict: string;
  pcPageId: number;
  pcStatus: number;
  pId: number;
  pcLosDay: number;
  pcService: string;
  pcIsSbSimulator: number;
  pcPayingStep: string;
  pcMinTrx: number;
  pName: string;
  pcIsSnap: number;
}

export interface IMerchantFeeState {
  loadingList: boolean;
  loadingDetail: boolean;
  loadingMasterPChannel: boolean;
  dataParentList: IMerchantFeeParentList[] | null;
  dataDetailList: { data: IMerchantFeeDetailList[]; merchant: IMerchantDataDetail } | null;
  dataDetail: IMerchantFeeDetailList | null;
  dataMasterPChannel: IMerchantPChannelMaster[] | null;
  error: any;
  paginationParent?: IPaginationMerchantFee;
  paginationDetail?: IPaginationMerchantFee;
}
